import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './pages/index';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Form from './pages/form'; // Make sure you have this component
import Thankyou from './pages/thankyou';
import TermsandConditions from './pages/termsandconditions';
function App() {
  return (
    <Router>
      <div style={{
        padding: '0',
        margin: '0',
        backgroundColor: '#000',
        color: 'white',
        fontFamily: 'lato,sans-serif',
        overflowY: 'auto', // Add this line
        width: '100%', // Change from 100vw if it exists
      }}>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsandConditions />} />
          <Route path="/form" element={<Form />} />
          <Route path="/registered" element={<Thankyou />} />
          <Route path="/" element={<Index />} />
          {/* Add more routes here if needed */}
        </Routes>
        {/* <footer style={{ paddingBottom: '10px',textAlign: 'center' }}>
  For more information, please read our <Link to="/privacy-policy" style={{ color: '#FFF' }}>Privacy Policy</Link>.
</footer> */}

      </div>
    </Router>
  );
}

export default App;

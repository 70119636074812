import React from "react";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const options = [
  { value: "booking photoshoots", label: "Booking Photoshoots" },
  { value: "becoming a photographer", label: "Becoming a Photographer" },
];

export default function MultipleSelect({ value, onChange, error, helperText }) {
  const handleSelectChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : "");
  };
  const theme = useTheme();
  // This will be true if the screen width is less than 'sm' breakpoint
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const customStyles = {
    indicatorSeparator: () => ({
      display: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "45px",
      backgroundColor: "#272727",
      color: "white",
      borderRadius: "7px",
      boxShadow: "none",
      cursor: "default",
      caretColor: "transparent",

      // border: state.isFocused ? 0 : 0,
      borderColor: error ? "red" : provided.borderColor,
      borderWidth: error ? "1px" : "0",
      // This line disable the blue border
      // boxShadow: state.isFocused ? 0 : 0,
      // '&:hover': {
      //    border: state.isFocused ? 0 : 0
      // },
      "&:hover": {
        borderColor: error ? "#d32f2f" : provided.borderColor, // Keep red on hover if error
      },
      "&::placeholder": {
        color: error ? "#d32f2f" : "#B3B4B3",
        // Change to the color you want for the placeholder text
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: error ? "#d32f2f" : "#B3B4B3",
      marginLeft: "12px",
      fontFamily: "lato,sans-serif",
    }),
    menu: (provided) => ({
      ...provided,

      width: "100%",
      backgroundColor: "#272727",
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      color: "white",
      paddingLeft: "20px",
      fontFamily: "lato, sans-serif",
      backgroundColor: state.isFocused
        ? "desired-color-for-focused" // Color when the option is focused/hovered
        : state.isSelected
        ? "desired-color-for-selected" // Color when the option is selected
        : "#272727", // Default background color
      ":active": {
        ...provided[":active"],
        backgroundColor: state.isSelected
          ? "desired-color-for-active-selected" // Color when the option is selected and active
          : "desired-color-for-active",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "12px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      'div[class$="-separator"]': {
        display: "none", // Hide the separator line
      },
    }),
  };
  const selectedOption = options.find((option) => option.value === value);

  return (
    <div>
      <Select
        value={selectedOption}
        onChange={handleSelectChange}
        options={options}
        styles={customStyles}
        placeholder="What are you looking for?"
      />
      {error && (
        <div
          style={{
            color: "#d32f2f",
            marginTop: "5px",
            fontSize: "12px",
            fontFamily: "lato, sans-serif",
            fontWeight: 50,
            textShadow: "0 1px 1px rgba(0, 0, 0, 0.1)", // Optional: slight shadow
          }}
        >
          {helperText}
        </div>
      )}
    </div>
  );
}

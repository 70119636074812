import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function CountdownTimer({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    // Cleanup the timer on component unmount
    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div
      className="flex items-center justify-center w-full rounded-md"
      style={{
        background:
          "linear-gradient(180deg, #FFFFFF -113.79%, rgba(0, 0, 0, 0.23) 80%)",
      }}
    >
      <div
        className={`flex ${
          isMobileSize ? "px-4" : "px-6"
        } py-2 rounded-md w-full items-start justify-between`}
      >
        <TimeBlock number={timeLeft.days} label="Days" />
        <Colon />
        <TimeBlock number={timeLeft.hours} label="Hours" />
        <Colon />
        <TimeBlock number={timeLeft.minutes} label="Minutes" />
        <Colon />
        <TimeBlock number={timeLeft.seconds} label="Seconds" />
      </div>
    </div>
  );
}

function calculateTimeLeft(targetDate) {
  const difference = +new Date(targetDate) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  return timeLeft;
}

function TimeBlock({ number, label }) {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className="text-center text-white">
      <div
        className={`font-semibold`}
        style={{
          fontSize: isMobileSize ? "2rem" : "2rem",
        }}
      >
        {number.toString().padStart(2, "0")}
      </div>
      <div
        style={{
          fontSize: isMobileSize ? "0.8rem" : "1rem",
        }}
      >
        {label}
      </div>
    </div>
  );
}

function Colon() {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      className={` text-white font-semibold text-center`}
      style={{
        fontSize: isMobileSize ? "2rem" : "2rem",
      }}
    >
      :
    </div>
  );
}

export default CountdownTimer;

import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import GifLoading from "../Components/gifloading";
import Logo from "../Components/logo";
import TitleHeading from "../Components/title";
import Subtitle from "../Components/subtitle";
import TextBody from "../Components/textbody";
import CountdownTimer from "../Components/timer";
import BasicTextFields from "../Components/simpleinput";
import MultipleSelect from "../Components/selectinput";
import ButtonSubmit from "../Components/submit";
import GifLoadingMobile from "../Components/gifloadingmobile";
import SocialMediaBar from "../Components/socialMedia";

function Index() {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [inputValue, setInputValue] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [mobileStep, setMobileStep] = React.useState(0);
  const [webStep, setWebStep] = React.useState(0);
  const [inputError, setInputError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  // Function to set steps based on hash
  const setStepFromHash = (hash) => {
    switch (hash) {
      case "#early-access":
        setMobileStep(1);
        setWebStep(0);
        break;
      case "#thank-you":
        setMobileStep(2);
        setWebStep(1);
        break;
      default:
        setMobileStep(0);
        setWebStep(0);
    }
  };

  // Effect to set initial step based on hash and handle hash changes
  useEffect(() => {
    const handleHashChange = () => {
      setStepFromHash(window.location.hash);
    };

    // Set initial step
    handleHashChange();

    // Add event listener for hash changes
    window.addEventListener("hashchange", handleHashChange);

    // Cleanup
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleSubmit = async () => {
    if (inputValue === "" || !inputValue.includes("@")) {
      setInputError(true);
      setHelperText("Please enter a valid email address");
      //do not proceed
      return;
    } else {
      setInputError(false);
      setHelperText("");
    }
    if (selectedOption === null) {
      setInputError(true);
      setHelperText("Please select an option");
      return;
    } else {
      setInputError(false);
      setHelperText("");
    }
    fetch("https://api.nyoko.com/api/v1/get_feedback/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: "Nyoko Early Access Request Form Nyoko website",
        email: inputValue,
        subject: "Early Access Request",
        message: selectedOption,
      }),
    });
    setSelectedOption(null);
    setInputValue("");
    window.location.hash = "#thank-you";
  };

  // Modify handleMobileStepChange to use hash
  const handleMobileStepChange = (step) => {
    if (step === 1) {
      window.location.hash = "#early-access";
    } else if (step === 2) {
      window.location.hash = "#thank-you";
    } else {
      window.location.hash = "";
    }
  };

  // Handle popstate event (back/forward button clicks)
  React.useEffect(() => {
    const handlePopState = () => {
      const hash = window.location.hash;
      if (hash === "" || hash === "#") {
        setMobileStep(0);
        setWebStep(0);
      } else if (hash === "#early-access") {
        setMobileStep(1);
        setWebStep(0);
      } else if (hash === "#thank-you") {
        setMobileStep(2);
        setWebStep(1);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  return (
    <div
      style={{
        padding: "0",
        margin: "0",
        backgroundColor: "black",
        color: "white",
        fontFamily: "lato,sans-serif",
        width: "100vw",
        maxHeight: "100vh", // Changed from height: 'auto' to minHeight: '100vh'
        overflowY: "auto", // This allows vertical scrolling
      }}
    >
      {isMobileSize ? (
        <div className="flex flex-col gap-4 w-100 ">
          {" "}
          {/* Added min-h-screen */}
          {mobileStep === 0 && (
            <div className="flex flex-col gap-3 w-100 h-100 justify-center px-6">
              <GifLoadingMobile />
              <div className="flex justify-center">
                <Logo fontSize={"text-[1.2rem]"} />
              </div>
              <div className="flex items-start justify-center">
                <TextBody text={"We are launching in"} fontSize={"1xl"} />
              </div>

              <CountdownTimer targetDate={new Date("2024-10-01")} />
              <ButtonSubmit
                label={"Request Early Access"}
                onClick={() => handleMobileStepChange(1)}
              />
            </div>
          )}
          {mobileStep === 1 && (
            <div className="flex flex-col gap-2 w-100 justify-center p-6">
              <Logo />
              <TitleHeading
                title="Explore the Future of Photography in Qatar."
                fontSize={"1.688rem"}
              />
              <Subtitle text="Nyoko is an on demand photography platform that connects talented photographers to those looking to create lasting memories through photoshoots." />
              <TextBody text={"We are launching in"} fontSize={"1xl"} />
              <CountdownTimer targetDate={new Date("2024-10-01")} />

              <BasicTextFields
                value={inputValue}
                onChange={handleChange}
                error={inputError}
                helperText={helperText}
              />
              <MultipleSelect
                value={selectedOption}
                onChange={handleSelectChange}
              />
              <ButtonSubmit
                label={"Request Early Access"}
                onClick={handleSubmit}
              />
              <div className="mb-4" />
            </div>
          )}
          {mobileStep === 2 && (
            <div className="flex flex-col gap-4 w-100 h-100 justify-center p-6">
              <Logo />
              <div className="my-4" />
              <TitleHeading title="Thank you." fontSize={"1.688rem"} />
              <Subtitle text="You have been added to our waiting list. We will be in touch with you soon." />
              <div className="" />
              <SocialMediaBar isMobile={true} />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-row gap-4 w-100 min-h-screen">
          {" "}
          {/* Added min-h-screen */}
          <div className="flex flex-col gap-4 w-1/2">
            <GifLoading path={"/main.gif"} />
          </div>
          {webStep === 0 && (
            <div className="flex flex-col gap-4 w-1/2 py-12 px-12">
              {" "}
              {/* Removed h-screen, added py-12 */}
              <Logo />
              <TitleHeading title="Explore the Future of Photography in Qatar." />
              <Subtitle text="Nyoko is an on demand photography platform that connects talented photographers to those looking to create lasting memories through photoshoots." />
              <TextBody text={"We are launching in"} />
              <CountdownTimer targetDate={new Date("2024-10-01")} />
              <BasicTextFields
                value={inputValue}
                onChange={handleChange}
                error={inputError}
                helperText={helperText}
              />
              <MultipleSelect
                value={selectedOption}
                onChange={handleSelectChange}
              />
              <ButtonSubmit
                label={"Request Early Access"}
                onClick={handleSubmit}
              />
            </div>
          )}
          {webStep === 1 && (
            <div className="flex flex-col gap-4 w-1/2 py-12 px-12">
              {" "}
              {/* Removed h-100, added py-12 */}
              <Logo />
              <div className="my-4" />
              <TitleHeading title="Thank You" />
              <Subtitle text="You have been added to our waiting list. We will be in touch with you soon." />
              <div className="my-4" />
              <SocialMediaBar />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Index;
